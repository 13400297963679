import { Link, PageProps, graphql } from 'gatsby'
import { slugifyString, createSearchPageRoute } from 'shared-utilities'
import { Author, AuthorPill } from 'component-library'
import Seo from '../../components/seo'
import { IContentAuthor } from '../../types'
import { useEffect, useRef } from 'react'
import PostLayout from '../../components/PostLayout/PostLayout'
import RelatedBlogs from '../../components/RelatedBlogs/RelatedBlogs'
import { ContentType, contentSortParams } from 'shared-utilities'
import MathJax from '../../components/MathJax/MathJax'
import { getAuthorObjectList } from '../../utils/author_helper'
import '../style.scss'
import '../code-block-style.scss'

interface IPageContext {
  slug: string
}

const BlogPostTemplate = ({
  data,
  pageContext,
}: PageProps<Queries.BlogPostBySlugQuery, IPageContext>) => {
  const { postData, allSeriesPosts } = data
  const articlesInSeries = allSeriesPosts.nodes.filter(
    (node) => node?.frontmatter?.series === postData?.frontmatter?.series
  )
  const authors = pageContext['authors'] || []
  const authorObjectList: IContentAuthor[] = getAuthorObjectList(authors)

  // Remove first and last forward slashes from the slug
  const updatedSlug = pageContext.slug.substring(1, pageContext.slug.length - 1)

  const commentBox = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const scriptEl = document.createElement('script')
    scriptEl.setAttribute('src', 'https://utteranc.es/client.js')
    scriptEl.setAttribute('crossorigin', 'anonymous')
    scriptEl.setAttribute('async', 'true')
    scriptEl.setAttribute('repo', 'PennyLaneAI/comments')
    scriptEl.setAttribute('issue-term', 'pathname')
    scriptEl.setAttribute('theme', 'github-light')
    commentBox.current?.appendChild(scriptEl)
  }, [])
  return (
    <>
      <PostLayout
        title={postData?.frontmatter?.title || ''}
        category={{
          label: postData?.frontmatter?.category || '',
          slug: createSearchPageRoute({
            category: postData?.frontmatter?.category || '',
            sort: contentSortParams.PUBLICATION_DATE_DESC,
            contentType: ContentType.BLOG,
          }),
        }}
      >
        <div className="section-xs px-3">
          <article
            className="blog-post"
            itemScope
            itemType="http://schema.org/Article"
          >
            <div className="blog-header">
              <p className="blog-date">{postData?.frontmatter?.date}</p>
              <h1
                itemProp="headline"
                className="blog-title smaller text-center"
              >
                {postData?.frontmatter?.title}
              </h1>
              <h2 className="text-center flex flex-wrap gap-y-3 justify-center">
                {authorObjectList.map((author, index) => (
                  <AuthorPill
                    key={`author-${index}`}
                    name={author?.name || ''}
                    image={author?.image || ''}
                    profileUrl={author?.profileUrl || ''}
                    username={author?.username || ''}
                    className="mr-6"
                    size="md"
                  />
                ))}
              </h2>
              {postData?.frontmatter?.series && (
                <div className="series">
                  <p>{`This post is part ${
                    articlesInSeries.findIndex(
                      (article) =>
                        article?.frontmatter?.title ===
                        postData?.frontmatter?.title
                    ) + 1
                  } of the "${postData.frontmatter.series}" series:`}</p>
                  <ol>
                    {articlesInSeries.map((article) => (
                      <li
                        key={article?.fields?.slug}
                        className={
                          article?.frontmatter?.title ===
                          postData?.frontmatter?.title
                            ? 'font-bold'
                            : ''
                        }
                      >
                        <Link to={`/blog${article?.fields?.slug}`}>
                          {article.frontmatter?.title}
                        </Link>
                      </li>
                    ))}
                  </ol>
                </div>
              )}
            </div>

            <div
              dangerouslySetInnerHTML={{
                __html: postData?.html || '',
              }}
              itemProp="articleBody"
              className="blog-content"
            />
            {authorObjectList.length > 0 && (
              <>
                <h2 className="Author__title">
                  About the {authorObjectList.length > 1 ? 'authors' : 'author'}
                </h2>
                {authorObjectList.map((author: IContentAuthor) => (
                  <Author
                    key={author?.name || ''}
                    name={author?.name || ''}
                    bio={author?.bio || ''}
                    image={author?.image || ''}
                    profileUrl={author?.profileUrl || ''}
                    username={author?.username || ''}
                    className="mb-8"
                  />
                ))}
              </>
            )}
          </article>

          <div className="blog-post--tags">
            <div className="mb-1">
              <em>Tags:</em>&nbsp;
              {postData?.frontmatter?.tags?.map((item, index) => {
                return (
                  <a
                    href={`/blog/tag/${slugifyString(item || '')}`}
                    className="post-tag-lin"
                    key={`tag-${index}`}
                  >
                    {item}
                    {postData?.frontmatter?.tags?.length &&
                    postData?.frontmatter?.tags?.length - 1 !== index
                      ? ','
                      : ''}
                    &nbsp;
                  </a>
                )
              })}
            </div>
            <div>
              <em>Last modified:</em>&nbsp;
              <span>
                <em>{postData?.frontmatter?.date}</em>
              </span>
            </div>
          </div>

          <div className="comments">
            <div className="utterances">
              <div ref={commentBox}></div>
            </div>
          </div>
        </div>
      </PostLayout>
      <RelatedBlogs slug={updatedSlug} />
      <MathJax />
    </>
  )
}

export const Head = ({ data }: PageProps<Queries.BlogPostBySlugQuery>) => {
  return (
    <Seo
      title={`${data?.postData?.frontmatter?.title} | PennyLane Blog`}
      description={
        data?.postData?.frontmatter?.description ||
        data?.postData?.excerpt ||
        ''
      }
      image={
        data?.postData?.frontmatter?.thumbnailImage
          ? data?.postData?.frontmatter?.thumbnailImage.publicURL || ''
          : ''
      }
    />
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($id: String) {
    postData: markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        category
        thumbnailImage {
          publicURL
        }
        series
      }
    }
    allSeriesPosts: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { series: { nin: ["", null] } }
      }
      sort: { fields: frontmatter___date, order: ASC }
    ) {
      nodes {
        frontmatter {
          series
          date
          title
        }
        fields {
          slug
        }
      }
    }
  }
`
