import { useMemo, useState } from 'react'
import { LoafCrumbs } from 'component-library'
import { useCheckBanner } from 'shared-utilities'
import './PostLayout.scss'

type PostLayoutProps = {
  title: string
  category: {
    label: string
    slug: string
  }
  children: React.ReactNode
}

type CommonCrumb = {
  [key: string]: {
    label: string
    href: string
  }
}

const COMMON_ROUTES: CommonCrumb = {
  BLOG: {
    label: 'Blog',
    href: '/blog',
  },
}

export default function PostLayout({
  children,
  title,
  category,
}: PostLayoutProps) {
  const [isBannerPresent, setBannerState] = useState(false)
  const crumbs = useMemo(
    () => [
      COMMON_ROUTES.BLOG,
      { label: category.label, href: category.slug },
      { label: title, href: category?.slug },
    ],
    [title, category]
  )

  useCheckBanner(setBannerState)

  return (
    <main className="PostLayout">
      <div
        className={`PostLayout__breadcrumbs ${
          isBannerPresent ? 'PostLayout__breadcrumbs--banner-visible' : ''
        }`}
      >
        <LoafCrumbs breadcrumbs={crumbs} />
      </div>
      <>{children}</>
    </main>
  )
}
